import React, {useContext} from "react"
import {Button, Col} from "react-bootstrap";
import checkedItemIcon from "../../static/images/item-checked.svg"
import {ModalContext} from "./layout";

const RenderPlanModules = ({ items, title }) => {
    return (
        items.length ? (
            <div className="mt-4 mx-auto w-75">
                <div className="offset-lg-1 d-flex flex-row">
                    <img src={checkedItemIcon} alt={title} style={{ visibility: "hidden" }} className="mx-4"/>
                    <p style={{ color: "#1F7ABF"}}>{title}</p>
                </div>
                {items.map((item) =>{
                    return (
                        <div className="d-flex flex-row my-1 offset-lg-1" key={item}>
                            <img src={checkedItemIcon} alt={item} className="mx-4"/>{item}
                        </div>
                    )}
                )}
            </div>
        ) : ("")
    )
}

function AppPricePackage({ image, planName, price, includes, addons, limits, color }) {
    const [, setShowModal] = useContext(ModalContext)
    const openModal = () => setShowModal(true)

    return (
        <Col
            sm={4}
            md={4}
            className="d-flex flex-column my-5 py-5"
            style={{ border: '1px solid #E5E7EB', borderRadius: ".5em"}}
        >
            <img src={image} alt={planName} className="align-self-center" style={{ width: "10em" }}/>
            <h4 className="my-5 fw-bold align-self-center" style={{ color: color }}>
                {planName.toUpperCase()}
            </h4>
            {price.toLowerCase() === "request pricing" ? (
                <Button style={{ backgroundColor: color}} className="align-self-center" onClick={openModal}>
                    {price}
                </Button>
            ): (
                <div className="align-self-center">
                    <sup style={{ color: "#6B7280", fontSize: "1.3em" }}>$</sup>
                    <span
                        style={{
                            color: "#6B7280",
                            fontSize: "2em",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            padding: "0 .12em"
                        }}
                    >
                            100
                        </span>
                    <sub style={{ color: "#6B7280" }}>per month</sub>
                </div>
            )}
            <RenderPlanModules items={includes} title="Includes"/>
            <RenderPlanModules items={limits} title="Limits"/>
            <RenderPlanModules items={addons} title="Add-ons"/>
            <Button
                style={{ backgroundColor: color}}
                className="align-self-center mt-5"
                onClick={openModal}
            >
                {price.toLowerCase() === "request pricing" ? price : "Request a Demo"}
            </Button>
        </Col>
    );
}

export default AppPricePackage;
