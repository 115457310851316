import React from "react"
import Footer from "../components/footer"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import {Col, Row} from "react-bootstrap";
import AppAccordion from "../components/AppAccordion";
import Cta from "../components/cta";
import startupPlanImage from "../../static/images/startup-plan.svg";
import growthPlanImage from "../../static/images/growth-plan.svg";
import enterprisePlanImage from "../../static/images/enterprise-plan.svg";
import AppPricePackage from "../components/PricePackage";
import {faqsPageFaqs} from "../_shared/constants";

const Pricing = ({ location }) => {

    const plans = [
        {
            image: startupPlanImage,
            name: "startup plan",
            price: "",
            color: "#22C55E",
            addons: [],
            includes: ["Community", "Startup Growth", "Mentoring", "Education", "CRM", "1 Hour on boarding"],
            limits: ["2 admins", "30 users", "50 contacts", "10 courses"]

        },
        {
            image: growthPlanImage,
            name: "Growth Plan",
            price: "Request Pricing",
            color: "#3B82F6",
            addons: ["Add admins, users and contacts", "Forms & Program Applications", "Video upload / Playback", "White Label"],
            includes: ["Community", "Startup Growth", "Mentoring", "Education", "CRM", "Add-ons", "5 Hour on boarding"],
            limits: ["5 admins", "150 users", "500 contacts"]
        },
        {
            image: enterprisePlanImage,
            name: "Enterprise",
            price: "Request Pricing",
            color: "#6366F1",
            addons:  ["Forms & Program Applications", "Video upload / Playback", "White Label"],
            includes: ["Community", "Startup Growth", "Mentoring", "Education", "CRM", "Add-ons", "Custom integrations", "Dedicated CSM"],
            limits: []
        },
    ]
    return (
        <Layout>
            <Seo title="Team Coaches Book A Demo" />
            <NavBar location={location} />
            <Row className="mx-0 mt-5 px-0">
                <Col className="col-8 mx-auto text-center" style={{ fontFamily: "Roboto" }}>
                    <h1  style={{ fontSize: '3em', letterSpacing: '.05em' }}>Platform Pricing</h1>
                    <p  style={{ fontSize: '1em' }}>The best innovation platform at the best price</p>
                </Col>
            </Row>
            <Row className="px-5 col-12 col-md-10 col-lg-10 mx-auto ">
                {plans.map(({ image, name, price, color, addons, includes, limits}) => {
                    return (
                        <AppPricePackage
                            key={name}
                            image={image}
                            planName={name}
                            price={price}
                            includes={includes}
                            addons={addons}
                            limits={limits}
                            color={color}
                        />
                    );
                })}
            </Row>
            <div style={{ margin: "0" }} className="d-flex flex-column justify-content-between">
                <Row className="faqs-container m-0">
                    <Col className="text-center col-12 mt-5 mb-2">
                        <h2 className="class-header">Frequently Asked Questions </h2>
                    </Col>
                    <Col className="col-12 col-md-10 col-lg-10 mx-auto pb-5">
                        <Row className="m-0">
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={faqsPageFaqs.sectionOne}/>
                            </Col>
                            <Col className="col-1 hide-on-small-screen" />
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={faqsPageFaqs.sectionTwo}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Cta
                    backgroundColor="back-blue"
                    buttonColor="blue"
                    title="Interested in Learning more?"
                    buttonText="Let's Talk"
                />
                <Footer />
            </div>
        </Layout>
    )
}

export default Pricing
