import React from "react"
import Accordion from 'react-bootstrap/Accordion';
import {Link} from "gatsby";

function AppAccordion({ items }) {
    return (
        <Accordion defaultActiveKey="0" flush>
            { items.map((item, index) => {
                return (
                    <Accordion.Item
                        eventKey={index}
                        key={index}
                        style={{
                            borderTop: ".1em solid #DDDDDD",
                            borderBottom: index === items.length -1 ? "none" : ".1em solid #DDDDDD"
                        }}
                    >
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body>
                            {item.body } { item.question === "How much does Team Coaches cost?" ? <Link to="/book-a-demo"> Book a meeting here </Link> : "" }
                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}
        </Accordion>
    );
}

export default AppAccordion;
